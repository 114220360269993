import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import Form from "./form";
import { globalHistory } from "@reach/router";
import { AnimatePresence, motion } from "framer-motion";
import {
  IoLogoVk,
  IoLogoWhatsapp,
  IoMailOpenOutline,
  IoArrowUpOutline,
  IoCall,
} from "react-icons/io5";
import Navone from "./svg/navone";
import Navtwo from "./svg/navtwo";
import Navthree from "./svg/navthree";
import Navfour from "./svg/navfour";
import Navfive from "./svg/navfive";
import Navsix from "./svg/navsix";
import Navseven from "./svg/navseven";
import Naveight from "./svg/naveight";
import {
  header,
  menu,
  navigation,
  logo,
  social,
  container,
  link,
  activeLink,
  btn,
  callback,
  shop,
  mobilenav,
} from "./navigation.module.scss";

function Navigation() {
  const [openForm, setOpenForm] = useState(false);
  const [openNav, setOpenNav] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") {
        setOpenNav(false);
      }
    });
  }, [setOpenNav]);

  return (
    <>
      <div
        className={menu}
        style={{
          transform: openNav && "translateX(0%)",
        }}
      >
        <div className={header}>
          <Link to="/" className={logo}>
            <img src="/logo.svg" alt="Логотип Cleanteam" />
          </Link>
          <div className={social}>
            <h2>Наши соц сети</h2>
            <div className={container}>
              <a href="https://vk.com/cleanteam_124" className={link}>
                <IoLogoVk />
              </a>
              <a href="https://wa.me/79832936466" className={link}>
                <IoLogoWhatsapp />
              </a>
              <a href="mailto:dreamteam.kr13@gmail.com" className={link}>
                <IoMailOpenOutline />
              </a>
            </div>
          </div>
        </div>
        <nav className={navigation}>
          <Link
            to="/drycleaning/"
            className={link}
            activeClassName={activeLink}
          >
            <Navone />
            <p>Химчистка</p>
          </Link>
          <Link
            to="/expresscleaning/"
            className={link}
            activeClassName={activeLink}
          >
            <Navtwo />
            <p>Экспресс уборка</p>
          </Link>
          <Link
            to="/cleaningafterrenovation/"
            className={link}
            activeClassName={activeLink}
          >
            <Navthree />
            <p>Уборка после ремонта</p>
          </Link>
          <Link
            to="/springcleaning/"
            className={link}
            activeClassName={activeLink}
          >
            <Navfour />
            <p>Генеральная уборка</p>
          </Link>
          <Link
            to="/generalcleaningkitchen/"
            className={link}
            activeClassName={activeLink}
          >
            <Navfive />
            <p>Генеральная уборка кухни</p>
          </Link>
          <Link
            to="/dailycleaning/"
            className={link}
            activeClassName={activeLink}
          >
            <Navsix />
            <p>Повседневная уборка</p>
          </Link>
          <Link
            to="/windowsandbalconies/"
            className={link}
            activeClassName={activeLink}
          >
            <Navseven />
            <p>Окна и балконы</p>
          </Link>
          <Link
            to="/additionalservices/"
            className={link}
            activeClassName={activeLink}
          >
            <Naveight />
            <p>Дополнительные услуги</p>
          </Link>
        </nav>

        <div className={callback}>
          <a href="https://cleaning-expert24.ru" className={shop}>
            Наш магазин
            <br /> бытовой химии
          </a>
          <button className={btn} onClick={() => setOpenForm(true)}>
            Заказать уборку <IoArrowUpOutline />
          </button>
          <p>или</p>
          <a href="tel:+79832936466">+7 (983) 293 64-66</a>
        </div>
      </div>
      <div
        className={mobilenav}
        style={{ transform: openForm ? "translateY(100%)" : "translateY(0%)" }}
      >
        <button
          onClick={() => setOpenNav(!openNav)}
          className={link}
          style={{
            backgroundColor: openNav && "rgba(255,255,255, 0.2)",
          }}
        >
          Услуги
        </button>
        <Link to="/" className={link}>
          Главная
        </Link>
        <button
          className={link}
          onClick={() => setOpenContact(!openContact)}
          style={{ backgroundColor: openContact && "rgba(255,255,255, 0.2)" }}
        >
          Контакты
        </button>
        <AnimatePresence>
          {openContact && (
            <motion.div
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              className={social}
            >
              <a href="https://vk.com/cleanteam_124" className={link}>
                <IoLogoVk />
              </a>
              <a href="https://wa.me/79832936466" className={link}>
                <IoLogoWhatsapp />
              </a>
              <a href="mailto:dreamteam.kr13@gmail.com" className={link}>
                <IoMailOpenOutline />
              </a>
              <a href="tel:+79832936466" className={link}>
                <IoCall />
              </a>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
      <AnimatePresence>
        {openForm && (
          <Form service="уборку" closeForm={() => setOpenForm(false)} />
        )}
      </AnimatePresence>
    </>
  );
}

export default Navigation;
