// extracted by mini-css-extract-plugin
export var activeLink = "navigation-module--activeLink--l9Kf1";
export var btn = "navigation-module--btn--ZHO+9";
export var callback = "navigation-module--callback--T7Z52";
export var container = "navigation-module--container--qSPQg";
export var header = "navigation-module--header--Wl62k";
export var link = "navigation-module--link--MDrny";
export var logo = "navigation-module--logo--KkDxG";
export var menu = "navigation-module--menu--HFFEd";
export var mobilenav = "navigation-module--mobilenav--Gt1QP";
export var navigation = "navigation-module--navigation--chQxI";
export var shop = "navigation-module--shop--U4fo5";
export var social = "navigation-module--social--GvYED";