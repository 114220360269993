import React from "react";

function Navone() {
  return (
    <svg
      width="23"
      height="25"
      viewBox="0 0 23 25"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.45 11.3636H15.1846V2.27273C15.1846 1.02273 14.0458 0 12.6538 0H10.1231C8.73114 0 7.5923 1.02273 7.5923 2.27273V11.3636H6.32692C2.83446 11.3636 0 13.9091 0 17.0455V25H22.7769V17.0455C22.7769 13.9091 19.9424 11.3636 16.45 11.3636ZM10.1231 2.27273H12.6538V11.3636H10.1231V2.27273ZM20.2461 22.7273H17.7154V19.3182C17.7154 18.6932 17.1459 18.1818 16.45 18.1818C15.754 18.1818 15.1846 18.6932 15.1846 19.3182V22.7273H12.6538V19.3182C12.6538 18.6932 12.0844 18.1818 11.3884 18.1818C10.6925 18.1818 10.1231 18.6932 10.1231 19.3182V22.7273H7.5923V19.3182C7.5923 18.6932 7.02288 18.1818 6.32692 18.1818C5.63095 18.1818 5.06153 18.6932 5.06153 19.3182V22.7273H2.53077V17.0455C2.53077 15.1705 4.23903 13.6364 6.32692 13.6364H16.45C18.5379 13.6364 20.2461 15.1705 20.2461 17.0455V22.7273Z" />
    </svg>
  );
}

export default Navone;
