import React from "react";

function Navsix() {
  return (
    <svg
      width="26"
      height="29"
      viewBox="0 0 26 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.5133 14.4367L23.5148 14.4373C24.423 14.8398 25 15.7372 25 16.7153V16.7297C25 18.113 23.8877 19.2253 22.5044 19.2253H20.2222H19.2222V20.2253V27.892H6.77778V20.2253V19.2253H5.77778H3.49556C2.11228 19.2253 1 18.113 1 16.7297V16.7153C1 15.7354 1.57776 14.8436 2.47644 14.4556L2.47646 14.4556L2.48666 14.4511L11.9622 10.2333L12.5556 9.96922V9.31975V8.40975V7.70573L11.8928 7.46832C10.3953 6.93192 9.40079 5.40138 9.7351 3.65982C9.98816 2.36437 11.0676 1.30246 12.3733 1.05856C14.1284 0.7358 15.6978 1.79605 16.1809 3.33641H15.2301C14.8468 2.4861 13.9908 1.89197 13 1.89197C11.6533 1.89197 10.5556 2.98968 10.5556 4.33641C10.5556 5.68314 11.6533 6.78086 13 6.78086C13.2422 6.78086 13.4444 6.98314 13.4444 7.2253V9.3053V9.95477L14.0378 10.2189L23.5133 14.4367ZM19.2222 17.3364V18.3364H20.2222H22.5044C23.389 18.3364 24.1111 17.6143 24.1111 16.7297C24.1111 16.0831 23.7301 15.5203 23.1729 15.2602L23.1645 15.2563L23.1561 15.2526L13.4061 10.9193L13 10.7388L12.5939 10.9193L2.85685 15.2468C2.25634 15.4975 1.88889 16.0871 1.88889 16.7153C1.88889 17.5989 2.59578 18.3364 3.49556 18.3364H5.77778H6.77778V17.3364V16.892H19.2222V17.3364Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Navsix;
