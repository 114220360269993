import React from "react";

function Navthree() {
  return (
    <svg
      width="22"
      height="28"
      viewBox="0 0 22 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.2499 5.25001L11.2499 6H6.24058L6.2007 1.74627C6.20271 1.33394 6.53789 1 6.95069 1H10.5026C10.9167 1 11.2526 1.33596 11.2526 1.75V2.75H12.2526H15.903C16.5373 2.75 17.1389 2.99576 17.68 3.49522L19.9477 5.76296L18.8876 6.82309L16.6074 4.54289L16.3145 4.25H15.9003H12.2499H11.2499L11.2499 5.25001ZM15.3256 11.9965L15.3264 12.0102L15.3277 12.0239L16.4411 24.2737C16.4411 24.2738 16.4411 24.2738 16.4411 24.2739C16.5738 25.738 15.421 27 13.9512 27H3.50045C2.02995 27 0.877383 25.7384 1.01057 24.2733L0.0148744 24.1828L1.01057 24.2733L2.124 12.0233C2.124 12.0233 2.12401 12.0233 2.12401 12.0233C2.2412 10.7343 3.32041 9.75 4.61389 9.75H12.8843C14.1844 9.75 15.2457 10.7355 15.3256 11.9965ZM7.50422 13.5957L7.50384 13.5961C7.01846 14.2036 6.23372 15.2243 5.56545 16.2794C5.23178 16.8062 4.91444 17.3613 4.67737 17.8912C4.45099 18.3972 4.24991 18.9828 4.24991 19.5398C4.24991 21.9351 6.34101 23.75 8.70069 23.75C11.1398 23.75 13.2499 21.9525 13.2499 19.5398C13.2499 18.9828 13.0487 18.3969 12.8223 17.8909C12.5851 17.3609 12.2677 16.8057 11.9339 16.2788C11.2655 15.2235 10.4808 14.2027 9.9959 13.596L9.99559 13.5957C9.36284 12.8048 8.13697 12.8048 7.50422 13.5957Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Navthree;
