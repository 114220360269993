import React from "react";
import Navigation from "./navigation";
import Logo from "./logo";
import { AnimatePresence } from "framer-motion";
import { main } from "../styles/main.module.scss";

function Layout({ children }) {
  return (
    <>
      <Navigation />
      <main className={main}>
        <Logo />
        <AnimatePresence exitBeforeEnter initial={false}>
          {children}
        </AnimatePresence>
      </main>
    </>
  );
}

export default Layout;
