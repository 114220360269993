import React from "react";

function Navseven() {
  return (
    <svg
      width="19"
      height="24"
      viewBox="0 0 19 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.625 2.4V21.6H2.375V2.4H16.625ZM16.625 0H2.375C1.06875 0 0 1.08 0 2.4V21.6C0 22.92 1.06875 24 2.375 24H16.625C17.9313 24 19 22.92 19 21.6V2.4C19 1.08 17.9313 0 16.625 0ZM3.5625 20.4H15.4375V13.2H3.5625V20.4ZM7.125 9.6H11.875V10.8H15.4375V3.6H3.5625V10.8H7.125V9.6Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default Navseven;
