import React from "react";
import { useForm } from "react-hook-form";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import axios from "axios";
import { MdOutlineClose } from "react-icons/md";
import {
  container,
  inputForm,
  formSubmit,
  title,
  politics,
  closeBtn,
} from "./form.module.scss";

function Form({ service, closeForm }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({ mode: "onBlur" });

  const url = "/api/telegram.php";

  const onSubmit = (data) =>
    axios
      .post(url, {
        telegram_message:
          `Заявка на ${service}` +
          "%0A" +
          `${data.name}` +
          "%0A" +
          `${data.phone}` +
          "%0A" +
          `${data.message}`,
      })
      .then(function () {
        closeForm();
        alert("Спасибо за заявку! В ближайшее время мы свяжемся с Вами.");
      });

  return (
    <motion.div
      initial={{ opacity: 0, y: 20, scale: 1.05 }}
      animate={{ opacity: 1, y: 0, scale: 1 }}
      exit={{ opacity: 0, y: 20, scale: 1.05 }}
      className={container}
    >
      <button className={closeBtn} onClick={closeForm}>
        <MdOutlineClose />
      </button>
      <form onSubmit={handleSubmit(onSubmit)}>
        <h2 className={title}>Заказать {service}</h2>
        <div className={inputForm}>
          <p>1. Имя</p>
          <input
            {...register("name", {
              required: "Введите Ваше имя",
              maxLength: {
                value: 30,
                message: "Слишком длинное имя...",
              },
              pattern: {
                value: /^[А-Яа-яЁё\s]+$/i,
                message: "Только русский язык",
              },
            })}
            placeholder="Введите Ваше имя"
            autoComplete="off"
          />
          {errors.name && <span>{errors.name.message}</span>}
        </div>

        <div className={inputForm}>
          <p>2. Телефон или соц. сети</p>
          <input
            {...register("phone", {
              required:
                "Оставьте Ваши контакты для связи и мы обязательно ответим",
              maxLength: {
                value: 30,
                message:
                  "Мы думали 30 символов должно хватить... может есть другая ссылка?",
              },
            })}
            placeholder="Телефон или ссылку на соц сети"
            autoComplete="off"
          />
          {errors.phone && <span>{errors.phone.message}</span>}
        </div>

        <div className={inputForm}>
          <p>3. Комментарий к услуге</p>
          <input
            {...register("message")}
            placeholder="Введите комментарий"
            autoComplete="off"
          />
        </div>
        <p className={politics}>
          Оставляя заявку Вы соглашаетесь
          <br />с <Link to="/policy">политикой конфиденциальности</Link>
        </p>
        <div className={formSubmit}>
          <button type="submit">Отправить</button>
        </div>
      </form>
    </motion.div>
  );
}

export default Form;
