import React from "react";

function Naveight() {
  return (
    <svg
      width="23"
      height="23"
      viewBox="0 0 23 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 12.5009V13.375H22V12.5H4.3125H3.3125V11.5V3.4713V3.47021L1 12.5009ZM1 12.5009L1.43438 12.5022L2.4375 12.5054V11.5022V3.47021C2.4375 2.1097 3.54636 1 4.90996 1C5.55602 1 6.19593 1.2648 6.65583 1.72438L6.65636 1.72491L7.27628 2.34348L7.71096 2.77721L8.29441 2.58576C9.18901 2.2922 10.008 2.48675 10.5852 2.94087L11.2828 3.48961L11.7118 3.06079L11.8222 3.17126L7.48511 7.50839L7.37535 7.39863L7.8144 6.95958L7.26187 6.26144C6.80335 5.6821 6.60785 4.86778 6.89823 3.97883L7.08899 3.39482L6.65425 2.9607L6.03538 2.34273L1 12.5009ZM3.875 20.4435V19.9983L3.5442 19.7004C2.86294 19.0869 2.4375 18.2187 2.4375 17.25V16.8125H20.5625V17.2505C20.5625 18.2192 20.1371 19.0873 19.4558 19.7009L19.125 19.9988V20.4439V22H18.25V21.6029V20.6029H17.25H5.75H4.75V21.6029V22H3.875V20.4435Z"
        stroke="currentColor"
        strokeWidth="2"
      />
    </svg>
  );
}

export default Naveight;
