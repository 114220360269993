exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-additionalservices-js": () => import("./../../../src/pages/additionalservices.js" /* webpackChunkName: "component---src-pages-additionalservices-js" */),
  "component---src-pages-certificates-js": () => import("./../../../src/pages/certificates.js" /* webpackChunkName: "component---src-pages-certificates-js" */),
  "component---src-pages-cleaningafterrenovation-js": () => import("./../../../src/pages/cleaningafterrenovation.js" /* webpackChunkName: "component---src-pages-cleaningafterrenovation-js" */),
  "component---src-pages-dailycleaning-js": () => import("./../../../src/pages/dailycleaning.js" /* webpackChunkName: "component---src-pages-dailycleaning-js" */),
  "component---src-pages-drycleaning-js": () => import("./../../../src/pages/drycleaning.js" /* webpackChunkName: "component---src-pages-drycleaning-js" */),
  "component---src-pages-expresscleaning-js": () => import("./../../../src/pages/expresscleaning.js" /* webpackChunkName: "component---src-pages-expresscleaning-js" */),
  "component---src-pages-generalcleaningkitchen-js": () => import("./../../../src/pages/generalcleaningkitchen.js" /* webpackChunkName: "component---src-pages-generalcleaningkitchen-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-info-js": () => import("./../../../src/pages/info.js" /* webpackChunkName: "component---src-pages-info-js" */),
  "component---src-pages-policy-js": () => import("./../../../src/pages/policy.js" /* webpackChunkName: "component---src-pages-policy-js" */),
  "component---src-pages-springcleaning-js": () => import("./../../../src/pages/springcleaning.js" /* webpackChunkName: "component---src-pages-springcleaning-js" */),
  "component---src-pages-subscription-js": () => import("./../../../src/pages/subscription.js" /* webpackChunkName: "component---src-pages-subscription-js" */),
  "component---src-pages-windowsandbalconies-js": () => import("./../../../src/pages/windowsandbalconies.js" /* webpackChunkName: "component---src-pages-windowsandbalconies-js" */)
}

