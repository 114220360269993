import React from "react";
import { Link } from "gatsby";
import { logo } from "../styles/main.module.scss";

function Logo() {
  return (
    <Link to="/" className={logo}>
      <img src="/logo.svg" alt="Логотип Cleanteam" />
    </Link>
  );
}

export default Logo;
