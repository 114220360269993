import React from "react";

function Navtwo() {
  return (
    <svg
      width="24"
      height="23"
      viewBox="0 0 24 23"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M18.2743 3.36585L18.9943 4.90293L20.56 5.60976L18.9943 6.31659L18.2743 7.85366L17.5543 6.31659L15.9886 5.60976L17.5543 4.90293L18.2743 3.36585ZM21.7143 13.4634C22.9714 13.4634 24 12.4537 24 11.2195C24 9.98537 21.7143 6.73171 21.7143 6.73171C21.7143 6.73171 19.4286 9.98537 19.4286 11.2195C19.4286 12.4537 20.4571 13.4634 21.7143 13.4634ZM11.4286 4.6V2.2439H13.7143C14.3657 2.2439 14.9714 2.43463 15.4857 2.74878L17.12 1.14439C16.1714 0.437561 15.0057 0 13.7143 0C12.0343 0 7.49714 0 7.42857 0V2.2439H9.14286V4.61122C7.10857 5.02634 5.48571 6.4961 4.85714 8.41463H7.32571C7.93143 7.4161 9.01714 6.73171 10.2857 6.73171C12.1371 6.73171 13.6457 8.17902 13.7029 9.98537L16 10.8268V10.0976C16 7.38244 14.0343 5.1161 11.4286 4.6ZM24 19.0732V20.1951L14.8571 23L6.85714 20.8234V22.439H0V10.0976H9.10857L16.1486 12.678C17.4286 13.1493 18.2857 14.361 18.2857 15.7073H20.5714C22.4686 15.7073 24 17.2107 24 19.0732ZM4.57143 20.1951V12.3415H2.28571V20.1951H4.57143ZM21.6 18.5907C21.4171 18.2205 21.0171 17.9624 20.5714 17.9624H14.4571C13.84 17.9624 13.2343 17.8615 12.6514 17.6707L9.93143 16.7844L10.6514 14.6527L13.3714 15.539C13.7257 15.6512 16 15.7073 16 15.7073C16 15.2922 15.7371 14.922 15.3486 14.7761L8.69714 12.3415H6.85714V18.4898L14.8229 20.6551L21.6 18.5907Z" />
    </svg>
  );
}

export default Navtwo;
